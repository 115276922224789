import { Flex, Heading, Icon, Text, IconButton } from "@chakra-ui/react";
import { memo, useCallback, useEffect, useRef, useState } from "react";

import Back from "components/SatelliteMenu/Back";
import Boundary from "components/Satellite/Boundary";
import { CgCollage } from "react-icons/cg";
import FarmMosaicMenu from "components/SatelliteMenu/FarmMosaicMenu";
import FieldImageMenu from "components/SatelliteMenu/FieldImageMenu";
import { FilterIndex } from "../enum";
import Menu from "ui/components/Satellite/Menu";
import MonthPicker from "components/Form/MonthPicker";
import SectorMosaicMenu from "components/SatelliteMenu/SectorMosaicMenu";
import SectorSelector from "components/SatelliteMenu/SectorSelector";
import { Unidade } from "core/features/maps/typings";
import useFarmStore from "core/features/farm/states/useFarmStore";
import useImageOverlayStore from "core/features/image/states/useImageOverlayStore";
import { FaChevronUp } from "react-icons/fa";

interface SatelliteMapMenuProps {
  indexOpen: FilterIndex | null;
  unit: Unidade | undefined;
  initialDate: string | undefined;
  finalDate: string | undefined;
  isVisible: boolean;
  handleChangeIsVisible: () => void;
}

function SatelliteMenu({
  indexOpen,
  unit,
  initialDate,
  finalDate,
  handleChangeIsVisible,
  isVisible,
}: SatelliteMapMenuProps) {
  const [minHeight, setMinHeight] = useState(200);
  const { farm, currentFinalDate, sector, field, update } = useFarmStore();

  const prevFarmState = useRef<any>();
  const prevSectorState = useRef<any>();
  const prevFieldState = useRef<any>();

  const {
    farmImageOverlay,
    setFarmImageOverlay,
    sectorImageOverlay,
    setSectorImageOverlay,
    fieldImageOverlay,
    setFieldImageOverlay,
  } = useImageOverlayStore();

  const handleMonthPickerChange = useCallback(
    (date: Date) => {
      // First day of the month
      const begin = new Date(date.getFullYear(), date.getMonth(), 1, 0, 0);
      // Last day of the month
      const end = new Date(date.getFullYear(), date.getMonth() + 1, 0, 0, 0);
      update({
        currentInitialDate: begin.toISOString().slice(0, 10),
        currentFinalDate: end.toISOString().slice(0, 10),
      });
    },
    [update]
  );

  useEffect(() => {
    if (unit) {
      update({
        farm: { id: unit.codigo, name: unit.nome },
        sector: undefined,
        field: undefined,
      });
    }
  }, [unit, update]);

  useEffect(() => {
    if (!initialDate || !finalDate) return;

    const today = new Date();
    const date =
      today >= new Date(initialDate) && today <= new Date(finalDate)
        ? today
        : new Date(finalDate);

    // First day of the month
    const begin = new Date(date.getFullYear(), date.getMonth(), 1, 0);
    // Last day of the month
    const end = new Date(date.getFullYear(), date.getMonth() + 1, 0, 0);

    update({
      initialDate,
      finalDate,
      currentInitialDate: begin.toISOString().slice(0, 10),
      currentFinalDate: end.toISOString().slice(0, 10),
    });
  }, [initialDate, finalDate, update]);

  useEffect(() => {
    if (farm?.id !== prevFarmState.current?.id) {
      setFarmImageOverlay(undefined);
      setSectorImageOverlay(undefined);
      setFieldImageOverlay(undefined);
    }

    if (sector?.id !== prevSectorState.current?.id) {
      setSectorImageOverlay(undefined);
    }

    if (field?.id !== prevFieldState.current?.id) {
      setFieldImageOverlay(undefined);
    }

    if (farm) prevFarmState.current = farm;
    if (sector) prevSectorState.current = sector;
    if (field) prevFieldState.current = field;
  }, [
    farm,
    sector,
    field,
    setFarmImageOverlay,
    setSectorImageOverlay,
    setFieldImageOverlay,
  ]);

  if (indexOpen !== FilterIndex.Imagens_Satelite) {
    return null;
  }

  return (
    <Menu isVisible={isVisible} minHeight={minHeight}>
      <Flex
        top={0}
        p={4}
        pb={6}
        bg="white"
        alignItems="center"
        justifyContent="space-between"
        position="sticky"
        zIndex={2}
      >
        {(sector || field) && <Back />}
        {!sector && !field && (
          <Heading as="h3" size="sm">
            Selecione um setor ou talhão
          </Heading>
        )}
        {sector && !field && (
          <Heading as="h3" size="sm">
            Selecione um talhão
          </Heading>
        )}
        {field && (
          <Text
            px={2}
            borderWidth={1}
            borderRadius="full"
            fontSize="20px"
            fontWeight="bold"
            display="flex"
            alignItems="center"
            title={`Talhão ${field?.name}`}
          >
            <Icon as={CgCollage} />
            {field?.name}
          </Text>
        )}
      </Flex>
      <SectorSelector mb={2} px={4} />
      <Flex px={4} alignItems="center" justifyContent="space-between">
        <Boundary />
        {initialDate && finalDate ? (
          <MonthPicker
            begin={new Date(`${initialDate}T00:00:01`)}
            end={new Date(`${finalDate}T23:59:59`)}
            value={
              currentFinalDate
                ? new Date(`${currentFinalDate}T00:00:01`)
                : undefined
            }
            onChange={handleMonthPickerChange}
            onOpen={() => setMinHeight(360)}
            onClose={() => setMinHeight(200)}
          />
        ) : null}
      </Flex>
      {field ? (
        <FieldImageMenu
          p={4}
          defaultValue={fieldImageOverlay}
          onChange={(v: any) =>
            setFieldImageOverlay({ url: v.url, bounds: v.boundingBox })
          }
        />
      ) : sector ? (
        <SectorMosaicMenu
          p={4}
          defaultValue={sectorImageOverlay}
          onChange={(v: any) =>
            setSectorImageOverlay({ url: v.url, bounds: v.boundingBox })
          }
        />
      ) : farm ? (
        <FarmMosaicMenu
          p={4}
          defaultValue={farmImageOverlay}
          onChange={(v: any) =>
            setFarmImageOverlay({ url: v.url, bounds: v.boundingBox })
          }
        />
      ) : (
        <></>
      )}
      <Flex
        padding="5px"
        flexDirection="row-reverse"
        borderTop="1px solid #84848440 "
        marginTop="10px"
      >
        <IconButton
          background="none"
          _hover={{}}
          _focus={{}}
          _active={{}}
          icon={<FaChevronUp color="#848484" />}
          aria-label="close-button"
          marginRight="20px"
          onClick={() => handleChangeIsVisible()}
        />
      </Flex>
    </Menu>
  );
}

export default memo(SatelliteMenu);
