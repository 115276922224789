import { useEffect, useState } from "react";
import { Box, Image } from "@chakra-ui/react";

import LogoText from "assets/images/logo-geoamaggi.svg";
import ButtonMenu from "./components/ButtonMenu";

import { useAuth } from "core/features/auth/store";

import styled from "styled-components";
import { PerfisEnum } from "core/features/users/enums";
import MapaTematicoIcon from "assets/images/mapa-tematico.svg";
import ArquivosIcon from "assets/images/arquivos.svg";
import TalhoesIcon from "assets/images/talhoes.svg";
import JustificativasIcon from "assets/images/justificativas.svg";

import UsuariosIcon from "assets/images/usuarios.svg";
import { useHistory } from "react-router";
import UserMenu from "./components/UserMenu";

const Spacer = styled.div`
  flex: 1;
`;

const SideBar = () => {
  const { user } = useAuth();
  const { push, replace } = useHistory();
  const history = useHistory();
  const [currentPath, setCurrentPath] = useState<string>(
    history.location.pathname
  );

  const handleGoToMapaTematico = () => {
    replace("/");
  };

  const handleGoToUsers = () => {
    push("/Usuarios");
  };

  const handleGoToTalhoes = () => {
    push("/Talhoes");
  };
  const handleGoToArquivos = () => {
    push("/Arquivos");
  };

  const handleGoToJustificativa = () => {
    push("/Justificativa");
  }

  useEffect(() => {
    history.listen((location) => {
      setCurrentPath(location.pathname);
    });
  }, [history]);

  const AgrodrivePermissions = [PerfisEnum.Administrador, PerfisEnum.Agrodrive];
  const AgrodriveValidation = () => {
    const result = AgrodrivePermissions.some((i) => user?.perfis?.includes(i));
    return result;
  };

  const JustificativasPermissions = [PerfisEnum.Agronomo, PerfisEnum.Gerente_Unidade, PerfisEnum.Gerente_Regional];
  const JustificativasValidation = () => {
    const result = JustificativasPermissions.some((i) => user?.perfis?.includes(i));
    return result;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="81px"
      height="100vh"
      zIndex={1012}
      alignItems="center"
      background="white"
      paddingTop="21px"
      boxShadow="0px 3px 5px #0000007D"
    >
      <Image src={LogoText} height="72px" width="88px" marginBottom="50px" />
      <ButtonMenu
        label="Mapas Temáticos"
        isActive={currentPath === "/"}
        srcImage={MapaTematicoIcon}
        onClick={handleGoToMapaTematico}
      />
      {user?.perfis?.includes(PerfisEnum.Administrador) && (
        <ButtonMenu
          label="Usuários"
          isActive={currentPath === "/Usuarios"}
          srcImage={UsuariosIcon}
          onClick={handleGoToUsers}
        />
      )}
      <ButtonMenu
        label="Edição de talhões"
        isActive={currentPath === "/Talhoes"}
        srcImage={TalhoesIcon}
        onClick={handleGoToTalhoes}
      />
      {AgrodriveValidation() && (
        <ButtonMenu
          label="Agrodrive"
          isActive={currentPath === "/Arquivos"}
          srcImage={ArquivosIcon}
          onClick={handleGoToArquivos}
        />
      )}
      {JustificativasValidation() && (
        <ButtonMenu
          label="Justificativas de Atrasos"
          isActive={currentPath === "/Justificativa"}
          srcImage={JustificativasIcon}
          onClick={handleGoToJustificativa}
          rotate={false}
        />
      )}
      <Spacer />
      <UserMenu />
    </Box>
  );
};

export default SideBar;
