import { createContext, ReactNode, useEffect, useState } from "react";
import { Safra, Unidade } from "core/features/maps/typings";

interface JustificativaContextProps {
  currentUnidade?: any;
  currentSafra?: any;
  currentCultura?: any;
  currentRegional?: any;
  currentMotivo?: any;
  nextUnidade?: any;
  nextSafra?: any;
  nextCultura?: any;
  nextRegional?: any;
  nextMotivo?: any;
  selecteds?: any[];
  apenasAprovados?: boolean | undefined;
  apenasJustificados?: boolean | undefined;
  apenasAprovadosCiente?: boolean | undefined;
  apenasPendentesJustificativas?: boolean | undefined;
  page?: number;
  size?: number;

  setPage: (prop: number) => void;
  setSize: (prop: number) => void;
  setApenasAprovados: (prop: boolean | undefined) => void;
  setApenasJustificados: (prop: boolean | undefined) => void;
  setApenasAprovadosCiente: (prop: boolean | undefined) => void;
  setApenasPendentesJustificativas: (prop: boolean | undefined) => void;
  setSelecteds: (prop: any[]) => void;
  setCurrentUnidade: (prop: any) => void;
  setCurrentSafra: (prop: any) => void;
  setCurrentCultura: (prop: any) => void;
  setCurrentRegional: (prop: any) => void;
  setCurrentMotivo: (prop: any) => void;
  setNextUnidade: (prop: any) => void;
  setNextSafra: (prop: any) => void;
  setNextCultura: (prop: any) => void;
  setNextRegional: (prop: any) => void;
  setNextMotivo: (prop: any) => void;
}

export const JustificativaContext = createContext<JustificativaContextProps>({
  currentSafra: undefined,
  currentUnidade: undefined,
  currentCultura: undefined,
  currentRegional: undefined,
  currentMotivo: undefined,
  nextSafra: undefined,
  nextUnidade: undefined,
  nextCultura: undefined,
  nextRegional: undefined,
  nextMotivo: undefined,
  selecteds: [],
  apenasAprovados: undefined,
  apenasJustificados: undefined,
  apenasAprovadosCiente: undefined,
  apenasPendentesJustificativas: undefined,
  page: 1,
  size: 10,

  setPage: (prop: number) => {},
  setSize: (prop: number) => {},
  setApenasAprovados: (prop: boolean | undefined) => {},
  setApenasJustificados: (prop: boolean | undefined) => {},
  setApenasAprovadosCiente: (prop: boolean | undefined) => {},
  setApenasPendentesJustificativas: (prop: boolean | undefined) => {},
  setSelecteds: (prop: any[]) => {},
  setCurrentSafra: (prop: any) => {},
  setCurrentUnidade: (prop: any) => {},
  setCurrentCultura: (prop: any) => {},
  setCurrentRegional: (prop: any) => {},
  setCurrentMotivo: (prop: any) => {},
  setNextSafra: (props: any) => {},
  setNextUnidade: (props: any) => {},
  setNextCultura: (props: any) => {},
  setNextRegional: (prop: any) => {},
  setNextMotivo: (prop: any) => {},
});

export const JustificativaContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [currentUnidade, setCurrentUnidade] = useState<any>();
  const [currentSafra, setCurrentSafra] = useState<any>();
  const [currentCultura, setCurrentCultura] = useState<any>();
  const [currentRegional, setCurrentRegional] = useState<any>();
  const [currentMotivo, setCurrentMotivo] = useState<any>();

  const [nextUnidade, setNextUnidade] = useState<any>();
  const [nextSafra, setNextSafra] = useState<any>();
  const [nextCultura, setNextCultura] = useState<any>();
  const [nextRegional, setNextRegional] = useState<any>();
  const [nextMotivo, setNextMotivo] = useState<any>();

  const [apenasAprovados, setApenasAprovados] = useState<boolean | undefined>();
  const [apenasJustificados, setApenasJustificados] = useState<boolean | undefined>();
  const [apenasAprovadosCiente, setApenasAprovadosCiente] = useState<boolean | undefined>();
  const [apenasPendentesJustificativas, setApenasPendentesJustificativas] = useState<boolean | undefined>();

  const [selecteds, setSelecteds] = useState<any[]>();
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);

  useEffect(() => {
    if (
      (!currentUnidade || !currentSafra || !currentCultura || currentMotivo) &&
      (nextSafra || nextUnidade || nextCultura || nextMotivo)
    ) {
      setCurrentSafra(nextSafra ? nextSafra : currentSafra);
      setCurrentUnidade(nextUnidade ? nextUnidade : currentUnidade);
      setCurrentCultura(nextCultura ? nextCultura : currentCultura);
      setCurrentMotivo(nextMotivo ? nextMotivo : currentMotivo);
    }

    if (nextUnidade?.nome !== currentUnidade?.nome) {
      setCurrentUnidade(nextUnidade);
    }

    if (nextSafra?.descricao !== currentSafra?.descricao) {
      setCurrentSafra(nextSafra);
    }

    if (nextCultura?.codigo !== currentCultura?.codigo) {
      setCurrentCultura(nextCultura);
    }

    if (nextRegional !== currentRegional) {
      setCurrentRegional(nextRegional);
    }

    if (nextMotivo !== currentMotivo) {
      setCurrentMotivo(nextMotivo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextUnidade, nextSafra, nextCultura, nextRegional, nextMotivo]);

  return (
    <JustificativaContext.Provider
      value={{
        currentUnidade,
        currentSafra,
        currentCultura,
        currentRegional,
        currentMotivo,
        nextSafra,
        nextUnidade,
        nextCultura,
        nextRegional,
        nextMotivo,
        selecteds,
        apenasAprovados,
        apenasJustificados,
        apenasAprovadosCiente,
        apenasPendentesJustificativas,
        page,
        size,
        setPage,
        setSize,
        setApenasAprovados,
        setApenasJustificados,
        setApenasAprovadosCiente,
        setApenasPendentesJustificativas,
        setSelecteds,
        setCurrentUnidade,
        setCurrentSafra,
        setCurrentCultura,
        setCurrentRegional,
        setCurrentMotivo,
        setNextSafra,
        setNextUnidade,
        setNextCultura,
        setNextRegional,
        setNextMotivo,
      }}
    >
      {children}
    </JustificativaContext.Provider>
  );
};
