import { Route, Switch } from "react-router-dom";

import FullWrapper from "components/wrappers/full-wrapper";
import HomePage from "./home";
import SharePage from "./share";
import Layout from "components/layout";
import { LoadScript } from "@react-google-maps/api";
import { useBoot } from "features/auth/hooks/useBoot";
import { useEffect } from "react";
import { useEnvs } from "features/env/store";
import { useFilters } from "features/filters/store";
import Users from "./users";
import Talhoes from "./talhoes";
import Arquivos from "./arquivos";
import ChangeProfile from "./changeProfile";
import Justificativa from "./justificativa";
import { TalhoesContextProvider } from "core/features/talhoes/contexts/Talhoes.context";
import { AgrodriveContextProvider } from "core/features/agrodrive/contexts/Agrodrive.context";
import { JustificativaContextProvider } from "core/features/justificativa/context/Justificativa.context";
import { PerfisEnum } from "core/features/users/enums";
import { useAuth } from "core/features/auth/store";

import { Image } from "@chakra-ui/react";
import logo from "../../../assets/images/logo-geoamaggi.svg";

function AppPages() {
  const { isLoading, init } = useBoot();
  const { isBootLoading } = useFilters();
  const { user } = useAuth();
  const { envs } = useEnvs();

  useEffect(() => {
    init();
  }, [init]);

  if (isLoading) {
    return (
      <FullWrapper>
        <Image w="300px" src={logo} mx="auto" />
      </FullWrapper>
    );
  }

  const AgrodrivePermissions = [PerfisEnum.Administrador, PerfisEnum.Agrodrive];
  const AgrodriveValidation = () => {
    const result = AgrodrivePermissions.some((i) => user?.perfis?.includes(i));
    return result;
  };

  return (
    <LoadScript googleMapsApiKey={envs?.react_app_maps_key!}>
      <Switch>
        <Route exact path="/">
          <Layout>
            <Layout.Content>
              <HomePage />
            </Layout.Content>
          </Layout>
        </Route>
        <Route exact path="/s/:code">
          <Layout>
            <Layout.Content>
              <SharePage />
              {!isBootLoading ? <HomePage /> : null}
            </Layout.Content>
          </Layout>
        </Route>
      </Switch>

      <Route exact path="/Usuarios">
        <Layout>
          <Layout.Content>
            <Users />
          </Layout.Content>
        </Layout>
      </Route>

      <Route exact path="/Talhoes">
        <Layout>
          <Layout.Content>
            <TalhoesContextProvider>
              <Talhoes />
            </TalhoesContextProvider>
          </Layout.Content>
        </Layout>
      </Route>

      <Route exact path="/Justificativa">
        <Layout>
          <Layout.Content>
            <JustificativaContextProvider>
              <Justificativa />
            </JustificativaContextProvider>
          </Layout.Content>
        </Layout>
      </Route>

      <Route exact path="/Arquivos">
        <Layout>
          <Layout.Content>
            <AgrodriveContextProvider>
              {AgrodriveValidation() ? <Arquivos /> : <HomePage />}
            </AgrodriveContextProvider>
          </Layout.Content>
        </Layout>
      </Route>

      <Route exact path="/Change-Profile">
        <Layout>
          <Layout.Content>
            <ChangeProfile />
          </Layout.Content>
        </Layout>
      </Route>
    </LoadScript>
  );
}

export default AppPages;
