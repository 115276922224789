import { Box, Flex, Image, Text } from "@chakra-ui/react";

import ButtonAmaggi from "./components/ButtonAmaggi";
import bg from "assets/images/bg-login.jpg";
import logoSvg from "assets/images/logo-geoamaggi.svg";

export default function LoginPage() {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      w="100%"
      h="100vh"
      bgImage={bg}
      bgRepeat="no-repeat"
      bgSize="cover"
      bgPos="center"
    >
      <Box maxW="500px" shadow="2xl" bgColor="white" p="48px" rounded="8px">
        <Image w="320px" src={logoSvg} mx="auto" />
        <Box pt="66px">
          <Text fontWeight="light" fontSize="28px" color="green.500">
            Olá, seja bem-vindo!
          </Text>
          <Text fontSize="14px">
            Realize seu login para continuar o acesso ao GEO Amaggi.
          </Text>
        </Box>
        <Flex gridGap="16px" flexDir="column" mt="32px">
          <ButtonAmaggi />
        </Flex>
      </Box>
    </Flex>
  );
}
