import { useContext, useState, useEffect } from "react";
import {
  Button,
  Flex,
  MenuButton,
  MenuItem,
  Text,
  Menu,
  MenuList,
} from "@chakra-ui/react";
import { useFiltersLists } from "core/features/filters/store/lists";
import { AiFillCaretDown } from "react-icons/ai";
import { useFiltersBaseLists } from "core/features/filters/hooks/useFiltersBaseLists";
import { useGetRegional } from "core/features/justificativa/hooks/useGetRegional";
import { useGetTiposAtraso } from "core/features/justificativa/hooks/useGetTiposAtraso";
import { JustificativaContext } from "core/features/justificativa/context/Justificativa.context";
import URL from "core/resources/urls";
import http from "infra/http";

const JustificativaHeader = () => {
  useFiltersBaseLists();

  const [ocupacoes, setOcupacoes] = useState([]);
  const { unidades, safras } = useFiltersLists();

  const {
    currentSafra,
    currentUnidade,
    currentCultura,
    currentRegional,
    currentMotivo,
    setNextSafra,
    setNextUnidade,
    setNextCultura,
    setNextRegional,
    setNextMotivo,
  } = useContext(JustificativaContext);

  const { data: regional } = useGetRegional();

  const { data: tiposAtraso } = useGetTiposAtraso();

  const getOcupacoes = async () => {
    const { data } = await http.get(URL.MAPAS.OCUPACOES(currentUnidade!.id, currentSafra!.id), {
      headers: {
        "api-version": "2.0",
      }
    });
    setOcupacoes(data);
  }

  useEffect(() => {
    setNextCultura(undefined);
    if (currentUnidade && currentSafra) {
      getOcupacoes();
    }
  }, [currentUnidade, currentSafra]);

  return (
    <Flex flexDirection="column">
      <Flex justifyContent="space-between" width="100%" padding="0 30px">
        <Text fontWeight="medium" fontSize="26px" color="#676C77">
          Justificativas - Agenda de Campo
        </Text>

        <Flex>
          <Menu flip>
            <MenuButton
              as={Button}
              border="1px solid"
              borderColor={"green"}
              borderRadius={0}
              background="white"
              color={"green"}
            >
              <Flex alignItems="center" gridGap="5px">
                {currentRegional || "Selecionar Regional"}
                <AiFillCaretDown />
              </Flex>
            </MenuButton>
            <MenuList zIndex={999}>
              <MenuItem onClick={() => setNextRegional(undefined)}>
                Selecionar Regional
              </MenuItem>
              {regional?.map((prop: string) => (
                <MenuItem key={prop} onClick={() => setNextRegional(prop)}>
                  {prop}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>

          <Menu flip>
            <MenuButton
              as={Button}
              border="1px solid"
              borderColor={"green"}
              borderRadius={0}
              background="white"
              color={"green"}
            >
              <Flex alignItems="center" gridGap="5px">
                {currentUnidade?.nome || "Selecionar Fazenda"}
                <AiFillCaretDown />
              </Flex>
            </MenuButton>
            <MenuList zIndex={999}>
              <MenuItem onClick={() => setNextUnidade(undefined)}>
                Selecionar Fazenda
              </MenuItem>
              {unidades.map((prop) => (
                <MenuItem key={prop.id} onClick={() => setNextUnidade(prop)}>
                  {prop.nome}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>

          <Menu>
            <MenuButton
              as={Button}
              border="1px solid"
              borderColor={"green"}
              borderRadius={0}
              background="white"
              color={"green"}
            >
              <Flex alignItems="center" gridGap="5px">
                {currentSafra?.descricao || "Selecionar Safra"}
                <AiFillCaretDown />
              </Flex>
            </MenuButton>
            <MenuList zIndex={999}>
              <MenuItem onClick={() => setNextSafra(undefined)}>
                Selecionar Safra
              </MenuItem>
              {safras.map((prop) => (
                <MenuItem key={prop.id} onClick={() => setNextSafra(prop)}>
                  {prop.descricao}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>

          <Menu>
            <MenuButton
              as={Button}
              border="1px solid"
              borderColor={"green"}
              borderRadius={0}
              background="white"
              color={"green"}
            >
              <Flex alignItems="center" gridGap="5px">
                {currentMotivo?.descricao || "Selecionar Motivo"}
                <AiFillCaretDown />
              </Flex>
            </MenuButton>
            <MenuList zIndex={999}>
              <MenuItem onClick={() => setNextMotivo(undefined)}>
                Selecionar Motivo
              </MenuItem>
              {tiposAtraso?.map((tipoAtraso: any) => (
                <MenuItem key={tipoAtraso.codigo} onClick={() => setNextMotivo(tipoAtraso)}>
                  {tipoAtraso.descricao}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>

          {currentUnidade && currentSafra && (
            <Menu flip>
              <MenuButton
                as={Button}
                border="1px solid"
                borderColor={"green"}
                borderRadius={0}
                background="white"
                color={"green"}
              >
                <Flex alignItems="center" gridGap="5px">
                  {currentCultura?.nome || "Selecionar Cultura"}
                  <AiFillCaretDown />
                </Flex>
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => setNextCultura(undefined)}>
                  Selecionar Cultura
                </MenuItem>
                {ocupacoes.length > 0 ? ocupacoes.map((ocupacao: any) => (
                  <MenuItem key={ocupacao?.codigo} onClick={() => setNextCultura(ocupacao)}>
                    {ocupacao?.nome}
                  </MenuItem>
                )) : (
                  <Text textAlign="center" opacity="0.5">Não há culturas para este filtro</Text>
                )}
              </MenuList>
            </Menu>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default JustificativaHeader;
