import { Box, BoxProps } from "@chakra-ui/react";

interface MenuProps extends BoxProps {
  children?: string | JSX.Element | JSX.Element[];
  isVisible: boolean;
  minHeight: number;
}

export default function Menu({ children, isVisible, minHeight, ...props }: MenuProps) {
  return (
    <Box
      top="70px"
      bg="white"
      width="100%"
      minH={`${minHeight}px`}
      maxH="calc(100vh - 200px)"
      borderRadius={8}
      position="absolute"
      overflowX="hidden"
      overflowY="auto"
      borderWidth={4}
      borderColor="white"
      transform={isVisible ? "scaleY(1)" : "scaleY(0)"}
      transition="all .5s"
      __css={{
        "&::-webkit-scrollbar": { width: "5px" },
        "&::-webkit-scrollbar-track": { width: "5px" },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "gray.200",
          borderRadius: "6px",
        },
        _hover: {
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "gray.500",
          },
        },
      }}
      {...props}
    >
      {children}
    </Box>
  );
}
